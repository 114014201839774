var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "goodsForm",
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "130px",
            rules: _vm.goodsRule
          }
        },
        [
          _c(
            "el-form-item",
            { staticStyle: { width: "60%" }, attrs: { label: "适用门店:" } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "block" },
                  attrs: { "label-width": "100px", label: "适用门店数量:" }
                },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "40%", margin: "5px" },
                      attrs: { value: _vm.tableData.length, disabled: "" }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("家")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { margin: "20px 0 10px" },
                  attrs: { data: _vm.tableData }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "门店名称",
                      "min-width": "20%"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "city",
                      label: "关联城市",
                      "min-width": "20%"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      label: "详细地址地址",
                      "min-width": "30%"
                    }
                  }),
                  _c(
                    "el-table-column",
                    { attrs: { label: "操作", "min-width": "10%" } },
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "text", disabled: "" } },
                        [_vm._v("移除")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.list.shops && _vm.list.shops.length > 5
                ? _c(
                    "el-button",
                    {
                      staticStyle: { display: "block", margin: "auto" },
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = true
                        }
                      }
                    },
                    [_vm._v("查看更多 > ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品名称:", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: { disabled: "", clearable: "", "show-word-limit": "" },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "图片:", prop: "logo" } }, [
            _c("img", {
              staticClass: "avatar",
              attrs: { src: _vm.formData.cover }
            })
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "原价：", prop: "original_price" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "30%" },
                  attrs: {
                    disabled: "",
                    value: _vm.formData.original_price / 100
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "售价：", prop: "sale_price" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "30%" },
                  attrs: {
                    disabled: "",
                    placeholder: "1-10位数字",
                    maxlength: "13",
                    value: _vm.formData.sale_price / 100,
                    clearable: ""
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "预估佣金：", prop: "commission" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "30%" },
                  attrs: { disabled: "", value: _vm.formData.commission / 100 }
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "销量：", prop: "sell_out" } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "block" },
                  attrs: { "label-width": "100px", label: "真实销量：" }
                },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "25%", margin: "5px" },
                      attrs: { value: _vm.formData.sale_count, disabled: "" }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("件")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "block", margin: "5px 0" },
                  attrs: {
                    label: "虚拟销量：",
                    prop: "base_sale_count",
                    "label-width": "90px"
                  }
                },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "25%", margin: "5px" },
                      attrs: { autocomplete: "off" },
                      model: {
                        value: _vm.formData.base_sale_count,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "base_sale_count", _vm._n($$v))
                        },
                        expression: "formData.base_sale_count"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("件")])],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "库存：", prop: "base_commission" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "30%" },
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.formData.stock,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "stock", $$v)
                    },
                    expression: "formData.stock"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("件")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "售卖时间：", prop: "base_commission" } },
            [
              _c("el-input", {
                staticStyle: { width: "35%" },
                attrs: { disabled: "", value: _vm.formData.sale_times }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "请选择状态",
                    disabled: ""
                  },
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                _vm._l(_vm.selectStatus, function(item) {
                  return _c("el-option", {
                    key: item.ID,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { margin: "2% 0 2% 5%" },
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "2%" },
              attrs: { type: "primary" },
              on: { click: _vm.enterDialog }
            },
            [_vm._v("保 存")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "全部适用门店",
            visible: _vm.dialogVisible,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { margin: "20px 0 10px" },
              attrs: { data: _vm.list.shops }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "门店名称", "min-width": "20%" }
              }),
              _c("el-table-column", {
                attrs: { prop: "city", label: "关联城市", "min-width": "20%" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  label: "详细地址地址",
                  "min-width": "30%"
                }
              }),
              _c(
                "el-table-column",
                { attrs: { label: "操作", "min-width": "10%" } },
                [
                  _c("el-button", { attrs: { type: "text", disabled: "" } }, [
                    _vm._v("移除")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }